<script setup>
import $ from "jquery";
import {ref} from "vue";

const nicknameExist = ref(true)

$(window).ready(function () {
  const $nickname = $('.zcynickname');
  const $actor = $('.actor-text')
  $nickname.addClass('animated fadeOutLeft')
  setTimeout(() => {
    $nickname.addClass('hidden')
    nicknameExist.value = false
  }, 210)
  setTimeout(() => {
    $actor.addClass('animated fadeOut')
    $actor.text('歌手')
    $actor.removeClass('animated fadeOut')
    $actor.addClass('animated fadeIn')
  }, 1000)
  setTimeout(() => {
    $actor.removeClass('animated fadeIn')
    $actor.addClass('animated rubberBand')
  }, 1200)
});


function unfollow() {
}

// 专辑图片
const cdImages = ref([
  {src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/住进这风里.webp', link: 'https://y.qq.com/n/ryqq/albumDetail/003J66VR31VBfZ'},
  {src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/保你好命.webp', link: 'https://y.qq.com/n/ryqq/albumDetail/003PDgB32xRP7g'},
  {
    src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/这一刻我掉进能够呼吸的大海.webp',
    link: 'https://music.163.com/#/album?id=179001640'
  },
  {src: 'https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/无法触及的神明.webp', link: 'https://y.qq.com/n/ryqq/albumDetail/0049IVKb0KS3dk'},
]);

// 判断元素是否在屏幕中央
function isElementInViewport(el) {
  if (!el) return false; // 确保 el 存在
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // 计算视口竖直中央的位置
  const viewportCenterY = windowHeight / 2;

  // 设置一个容差值，例如 50 像素
  const tolerance = 700;

  // 判断元素的顶部是否在视口竖直中央范围内
  console.log(rect.top, rect.bottom);
  return rect.top >= (viewportCenterY - tolerance) && rect.top <= (viewportCenterY + tolerance);
}

$(window).on('scroll load', function () {
  const $song_left = $('.song-card-left');
  // 检查是否找到元素
  if ($song_left.length) {
    $song_left.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this)) {
        $this.removeClass('hidden').addClass('animated fadeInLeft');
      }
    });
  }
  const $song_right = $('.song-card-right');
  // 检查是否找到元素
  if ($song_right.length) {
    $song_right.each(function () {
      const $this = $(this); // 当前元素
      // 检查当前元素是否在视口中
      if (isElementInViewport(this)) {
        $this.removeClass('hidden').addClass('animated fadeInRight');
      }
    });
  }
});

// 触发一次以确保在加载时检查
$(window).trigger('scroll');

</script>

<template>
  <div class="whole">
    <div class="background-image-container">
      <br>
      <br>
      <div style="margin-left: 15rem; display: flex">
        <vs-avatar size="250" circle>
          <img src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/战斗天使ZCY.webp" alt="">
        </vs-avatar>
        <div style="display: flex">
          <div style="margin-top: 1.6rem; margin-left: 1rem">
            <div style="display: flex">
              <p v-if="nicknameExist" class="animated zcynickname">战斗天使ZCY</p>
              <p class="animated fadeInRight"
                 style="font-family: '阿里妈妈刀隶体 Regular'; font-size: 48px; color: #d3dce6">
                爱唱歌的ZCY
              </p>
            </div>
            <div style="height: 1.2rem;margin-left: 0.1rem; display: flex">
              <img src="../assets/icon/Subtraction.webp" alt="">
              <p class="actor-text">演员</p>
            </div>
            <div style="display: flex; margin-top: 2rem">
              <div style="display: flex">
                <div class="data-statistics-number animated fadeInLeft">2229.9w</div>
                <div class="data-statistics-text">获赞</div>
              </div>
              <div style="display: flex">
                <div class="data-statistics-number animated fadeInLeft">123</div>
                <div class="data-statistics-text">关注</div>
              </div>
              <div style="display: flex">
                <div class="data-statistics-number animated fadeInLeft">160.0w</div>
                <div class="data-statistics-text">粉丝</div>
              </div>
            </div>
            <n-dialog-provider>
              <div class="follow-button" @click="unfollow">
                <div style="font-size: 14px">特别关注</div>
                <img style="height: 15px; margin-left: 0.5rem" src="../assets/icon/daosanjiao.webp" alt="">
              </div>
            </n-dialog-provider>
          </div>
        </div>
      </div>
      <div style="color: white; font-size: 20px; font-weight: bolder; margin-top: 4rem">所有专辑</div>
      <div style="color: white; font-size: 13px; font-weight: lighter">CDS OF ZCY</div>
      <div class="animated fadeIn" style="width: 800px;margin-left: auto; margin-right: auto; margin-top: 2.5rem">
        <el-carousel :interval="4000" type="card" height="400px">
          <el-carousel-item v-for="(item, index) in cdImages" :key="index">
            <a :href="item.link" target="_blank">
              <img :src="item.src" alt="Carousel Image" style="width: 100%; height: auto;">
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div style="color: white; font-size: 20px; font-weight: bolder; margin-top: 7rem;">在线播放</div>
      <div style="color: white; font-size: 13px; font-weight: lighter">PLAY ONLINE</div>
      <a-timeline style="margin-top: 2rem" mode="alternate">
        <a-timeline-item class="song-card-right hidden">
          <div>
            <div class="timeline-text">2023.10.22</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/日不落.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>日不落</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">我要送你日不落的想念 寄出代表爱的明信片</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/日不落.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-left hidden">
          <div class="timeline-item-left">
            <div class="timeline-text">2023.11.15</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/下雨天.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>下雨天</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">下雨天了怎么办我好想你 不敢打给你我找不到原因</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/下雨天.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-right hidden">
          <div>
            <div class="timeline-text">2023.11.17</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/带你去旅行.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>带你去旅行</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">我想要带你去浪漫的土耳其 然后一起去东京和巴黎</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/带你去旅行.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-left hidden">
          <div class="timeline-item-left">
            <div class="timeline-text">2023.12.26</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/素颜.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>素颜</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">如果再看你一眼 是否还会有感觉</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/素颜.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-right hidden">
          <div>
            <div class="timeline-text">2024.01.28</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/住进这风里.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>住进这风里</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">我想和你住进这风里 我想拥抱你在这黎明</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/住进这风里.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-left hidden">
          <div class="timeline-item-left">
            <div class="timeline-text">2024.01.30</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/保你好命.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>保你好命</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">你可以Body Shining Body Shining像个明星</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/保你好命.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-right hidden">
          <div>
            <div class="timeline-text">2024.02.14</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/这一刻我掉进能够呼吸的大海.webp"
                     alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>这一刻我掉进能够呼吸的大海</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">没有你的未来失去天空的色彩</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/这一刻我掉进能够呼吸的大海.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class=" song-card-left hidden">
          <div class="timeline-item-left">
            <div class="timeline-text">2024.07.01</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/搁浅.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>搁浅</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">读完了依赖 我很快就离开</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/搁浅.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-right hidden">
          <div>
            <div class="timeline-text">2024.08.30</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/无法触及的神明.webp" alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>无法触及的神明</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">你像是夜晚的神明 掠夺我身体 不留下这一丝痕迹</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/无法触及的神明.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item class="song-card-left hidden">
          <div class="timeline-item-left">
            <div class="timeline-text">2024.09.17</div>
            <div class="my-audio-player">
              <div style="display: flex; padding: 1.2rem; margin-left: 0.5rem">
                <img class="cd-image" src="https://zcy-image-1317779439.cos.ap-shanghai.myqcloud.com/zcy-image/变态少女想人记.webp"
                     alt="">
                <div>
                  <div class="player-in-song-description-text">
                    <div>变态少女想人记</div>
                    <div style="margin-left: 0.2rem">— 钟晨瑶</div>
                  </div>
                  <div class="song-word-text">上厕所的时候不敢想你 怕臭臭的空气亵渎了你</div>
                </div>
              </div>
              <div style="display: flex; justify-items: center">
                <audio controls ref="audioPlayer" class="player-line"
                       style="height: 40px; width: 350px;">
                  <source src="../assets/music/变态少女想人记.mp3"/>
                </audio>
              </div>
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
      <div class="song-text">持续更新中...</div>
      <n-back-top :right="60" :bottom="60"/>
    </div>
  </div>
</template>

<style scoped>
.whole {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.background-image-container {
  width: 1500px;
}

.song-text {
  justify-content: center;
  color: rgba(255, 255, 255, 0.52);
  margin: 3rem auto;
}

.song-text-line {
  width: 800px;
  margin-bottom: 5.4rem;
  cursor: pointer;
}

.player-and-song-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-line {
  margin-bottom: 5rem;
  margin-left: 1.5rem;
}

.zcynickname {
  color: white;
  font-size: 36px;
  font-weight: initial
}

.animated.fadeInRight {
  animation-duration: 1.5s; /* 设置动画持续时间为 2 秒 */
}

.animated.fadeOutLeft {
  animation-duration: 1s; /* 设置动画持续时间为 2 秒 */
}

.actor-text {
  margin-left: 0.3rem;
  color: white;
  font-weight: 400;
  font-size: 16px
}

.data-statistics-number {
  color: white;
  font-size: 20px;
  font-weight: 1000;
  padding: 0.1rem;
}

.follow-button {
  width: 9rem;
  height: 2rem;
  background-color: #383838;
  color: #ebebeb;
  border-radius: 5px;
  margin-top: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.follow-button:hover {
  background-color: #212121;
}

.data-statistics-text {
  color: gray;
  font-size: 15px;
  padding: 0.3rem;
  margin-right: 0.5rem;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.my-audio-player {
  height: 150px;
  width: 400px;
  border-radius: 20px;
  background-color: rgba(247, 247, 247, 0.3);
}

.player-in-song-description-text {
  margin-left: 0.3rem;
  display: flex;
  color: #ebebeb;
  font-size: 15px;
  font-weight: 500;
}

.song-word-text {
  margin-left: 0.3rem;
  margin-top: 0.6rem;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.timeline-text {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: rgba(245, 245, 245, 0.4);
}

.timeline-item-left {
  padding-left: 21rem
}

.cd-image {
  height: 50px;
  width: 50px;
  border-radius: 10px
}

.animated.fadeInLeft {
  animation-duration: 3s; /* 设置动画持续时间为 2 秒 */
}

.animated.fadeInRight {
  animation-duration: 3s; /* 设置动画持续时间为 2 秒 */
}


</style>