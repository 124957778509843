<script setup>

import {listMessage, sendMessage} from "@/api/messageService";
import {onMounted, reactive, ref} from "vue";
import {Waterfall} from "vue-waterfall-plugin-next";
import {ElMessage} from "element-plus";

const state = reactive({
  messageList: []
})
const loading = ref(true)

const visible = ref(false)

const inputName = ref()
const inputContent = ref()

const getMessageList = async () => {
  return await listMessage()
};

onMounted(() => {
  getMessageList().then(result => {
    state.messageList = result
    loading.value = false
  })
})

function transferDate(dateStr) {
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}

const close = () => {
  inputName.value = ''
  inputContent.value = ''
  visible.value = false
}

// 按钮防重复点击
const buttonLoading = ref(false)

const send = (message) => {
  buttonLoading.value = true
  sendMessage(message).then((result) => {
    if (result === -2) {
      ElMessage({
        message: 'id/留言内容违规',
        type: 'warning',
      })
      inputContent.value = ''
      inputName.value=''
    } else if (result === 1) {
      ElMessage({
        message: '留言成功',
        type: 'success',
      })
      inputContent.value = ''
      inputName.value=''
    } else {
      ElMessage.error('服务器异常 留言失败')
    }
    getMessageList().then(messages => {
      state.messageList = messages
    })
    visible.value = false
    buttonLoading.value = false
  })
}

const wordsList = ref([
  '难过的时候肯定会偷偷哭，就是不告诉你们，难过怎么可能不哭啊，难过难过的时候不哭，我要憋坏了，但是我不会一直难过，我也没什么难过的，没什么难过的事',
  '大家好，你们谁能看得到我，我给你们说我就是播一下下，我就下了，谁给我打电话，嗯什么意思，鹅鹅鹅我睡不着觉～突击的开一下，我看看大家。嗯～哈哈哈 就是我想我的工作人员肯定都睡了，我偷偷地开个直播，鹅鹅鹅～没有 因为我觉得这样会～鹅鹅鹅～ 看得到，天呐！',
  '鹅鹅鹅～别再进人了，快我关了呵呵呵～看到大家还是蛮开心的嗯',
  '其实对大家我最想说的还是感谢，然后我也希望你们能够有自己热爱的事情，有自己的幸福，有自己的坚持和梦想，然后勇敢的去做自己想做的事情。希望你们能越来越好！',
  '我觉得天天开心并不是一件容易的事情。很多时候都会有…嗯～迫不得已 身不由己啊 但是尽量吧 调整心态去面对生活',
  '而且我很担心说，在群里面发消息，可能跟你们说的少了，你们会觉得我不在乎你们，或者是你们变得不重要的nonono不是的，就是我真的很感谢大家，就是很感谢很感谢。然后我也会记在心里面，我也很需要你们，如果我将来发的消息少了，也绝对不是我不在乎你们，我是希望我能够对得起你们现在的支持，嗯因为道路还很长，我也不知道我接下来会面临什么。会有会遇见什么，对，但是我就想踏踏实实一步一步走好，做好每一天的事情吧，让每个人都支持我理解我是很难的，但是我已经有了你们，愿意支持我。我已经觉得很幸运了',
  '路演那时间好辛苦，每天都要去不同的城市。可是大家也好辛苦陪我走过一个又一个城市。每次线下见面 你们总有满分的热情 ~ 我收到很多留言 ，关心我的或是担心我的 或者是分享有趣的事情 ~为我写的歌用心提的建议 那么多好玩的剪辑 新奇的观点 ~大家真的好可爱 ！有时会想自己何德何能 ，可以收获这么多的喜欢与惊喜 ?大家像骑士保护着我、关心着我、支持着我 我常常也为你们感到自豪 ~大家不仅多才多艺 幽默有趣 满载着热情的同时 又总是能在混乱的人群中维持 好秩序 我会再疲惫了 孤单的或者焦虑的时候 就去看看你们都在聊什么…然后……就被逗笑了 ',
  '谢谢愿意陪伴我成长的你 ~ 谢谢，愿意理解我的你~ 谢谢到现在依旧守候在我身后的你~',
  '就是有很多uu们会嗯～怎么说呢下定义也好贴标签也好但是我对于昂~演员这个事情其实是一个很平常的心态我就是觉得它就是一份职业而已和大家一样都是要工作上班下班，我自己是我自己是没给就是这个职业添加太多滤镜的，但是我认为演员她本身是要嗯～去多经历生活多体验，所以我也会说有愿意有各种各样的尝试～嗯就是那些对于我来说新鲜的东西我很愿意去试一试我觉得在哪个过程当中也是一种学习成长和进步吧就像我一直不太熟悉直播但是播了几次我感觉不会像之前那么那么紧张了之类的',
  '但是这些事情是急不得的 所以一步一步积累 很多事情都需要我们一步步积累去做 不忘初心就好 也希望你的愿望可以真的实现 我也希望我可以继续加油 不要辜负大家的期待',
  '就是如果我将来发的消息少了，也绝对不是我不在乎你们，我是希望我能后对的你们现在的支持。因为道路还很长我也不知道我接下来会面临什么，会有会遇见什么，但是我就想踏踏实实的一步一步的走好，做好每一天的事情吧。然后就是也真的真的very very very 感谢大家能够我一直播就来捧我的场，支持我也好鼓励我也好安慰我也好，那些话我都看在心里，love you love you everyone！',
  '～一直想剪的视频。剪出来了...深夜投放吧...应该不算肉麻吧 这些片段一闪而过，背后是我无数个被触动的时刻。还有更多可爱的事情，没有放进去～靴靴哩们，让我感受到了世界满满的温暖与爱～年未了，大家都忙碌起来~忙各自的事情~多陪陪家人~劳逸结合、注意保暖～加油吧!路还很长~加油吧加油吧',
  '谢谢你见证了我所有的开始，也见证了我的成长和蜕变。你要知道，你其实是我心底里头坚持走下去的底气，我也希望我可以成为你支撑你的力量，那接下去的路一定要一起走完喔~',
  '读了这么多信，像在和不同的朋友交谈，我很珍惜每一注关心、每一个建议、每一丝担心、每一份祝福。大家在我身上投注了期望，我自己也投注了期望，我把这股气含在肚子里，争取不负自己、不负韶华。无怨无悔也是我的追求。其实最让我欣慰的是，大家能够通过我相遇相知。很多人成为了朋友，相互陪伴、互相帮助、彼此分享，或聊以慰籍，或出谋划策、或答疑解惑、或悠闲消遣......大家永远和和睦睦、共同进步是我的心愿~也希望大家彼此鼓励、共同进步、在各行各业发光发热、在生活里发光发热，这样的话，我也会为你们感到骄傲和自豪哒!',
  '我想这个世界的每一处角落，我想这夜晚的风，我想这天空的云，我想这路边的歌声，我想我的好朋友，我想大家，我想你们。',
  '嗯我可能感受不到，你多说说话我就感受到了，我得看到你，你也不能天天就是在屋里默念我的名字，嗯然后什么都不让我看见，对吧你的ID也没有出现过，那我肯定就感受不到呀。这个喜欢和爱是需要表达的，我们要用尽我们的生命去表达我们自己，要珍惜每一天',
  '是的，都是好朋友，我也很替你们的相遇感到开心。',
  '我去拍了一个短剧，是芒果tv重点项目，我也很感谢他们选择了我，短短几天 其实感悟很多 在这个组里我好快乐。 我总是把目光放在长剧集或者电影上 刚毕业的我骨子里总有些许傲气和倔强的追求，但我发现哪怕是短剧，只要团队和对手好都能进步 在哪儿都能遇到很好的伙伴和对手演员。在发挥空间极小的条件下尽力发挥。经历着经历着，我心里有好多股力量在不断涌动。我是演员，我应该简单纯粹，不管做什么，我纯粹地对角色负责 为角色思考，想角色所想、做角色所做、就像角色真的活在这个世界上就好了~哪有一个活生生的人永远只会在固定的某种媒介里出现。我能做着自己喜欢的工作，并且我认为是很有趣很有意思的事情，我已经感到幸运了',
  '我想 我还要演好多好多好多戏 才可以慢慢成长起来 直到有一天能够游刃有余。有的时候我会焦虑 很渴望快点遇到一个角色一个故事 动人 被铭记。朋友宽慰我说 其实我已经算幸运了 郑梓妍被那么多人喜欢~我也很感谢郑梓妍呀。但我还要继续加油努力呀~!',
  '敲开心的birthday🎂我也来打卡啦～你们给的惊喜，必须来看看！希望大家都平安喜乐 万事顺遂！希望爱我的人都越来越好哈哈哈哈哈哈哈哈哈哈！！！！！！！！',
  '呜呜呜 好想哭 好委屈 好想跟你们打小报告'

])


</script>

<template>
  <div class="whole">
    <div class="background-image-container">
      <audio loop autoplay>
        <source src="../assets/music/彩虹-周杰伦.mp3">
      </audio>
      <div class="animated fadeIn" style="color: white; font-size: 20px; font-weight: bolder; margin-top: 3rem">
        听瑶瑶的话
      </div>
      <div class="animated fadeIn" style="color: white; font-size: 13px; font-weight: lighter">LISTEN TO ZCY</div>
      <div class="message-11">
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card" style="height: 2.6rem; background-color: #fe0040;">
              {{ wordsList[0] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: #fe0040;">
              {{ wordsList[1] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: #fe0040;">
              {{ wordsList[2] }}
            </div>
          </div>
        </marquee>
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card" style="height: 2.6rem; background-color: #f7532f; margin-left: 3rem">
              {{ wordsList[3] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: #f7532f;">
              {{ wordsList[4] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: #f7532f;">
              {{ wordsList[5] }}
            </div>
          </div>
        </marquee>
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card" style="height: 2.6rem; background-color: #eed159; margin-left: 1rem">
              {{ wordsList[6] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: #eed159;">
              {{ wordsList[7] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: #eed159;">
              {{ wordsList[8] }}
            </div>
          </div>
        </marquee>
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card" style="height: 2.6rem; background-color: #41c047; margin-left: 4rem">
              {{ wordsList[9] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: #41c047;">
              {{ wordsList[10] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: #41c047;">
              {{ wordsList[11] }}
            </div>
          </div>
        </marquee>
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card" style="height: 2.6rem; background-color: rgba(0,255,234,0.9);">
              {{ wordsList[12] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: rgba(0,255,234,0.9);">
              {{ wordsList[13] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: rgba(0,255,234,0.9);">
              {{ wordsList[14] }}
            </div>
          </div>
        </marquee>
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card"
                 style="height: 2.6rem; background-color: rgba(0, 0, 255,0.9); margin-left: 3.5rem">
              {{ wordsList[15] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: rgba(0, 0, 255,0.9);">
              {{ wordsList[16] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: rgba(0, 0, 255,0.9);">
              {{ wordsList[17] }}
            </div>
          </div>
        </marquee>
        <marquee scrolldelay="150">
          <div style="display: flex">
            <div class="zcy-words-card"
                 style="height: 2.6rem; background-color: rgba(139, 0, 255 ,0.9); margin-left: 2.5rem">
              {{ wordsList[18] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem; background-color: rgba(139, 0, 255 ,0.9);">
              {{ wordsList[19] }}
            </div>
            <div class="zcy-words-card"
                 style="height: 2.6rem; margin-left: 1rem;  background-color: rgba(139, 0, 255 ,0.9);">
              {{ wordsList[20] }}
            </div>
          </div>
        </marquee>
      </div>
      <div class="animated fadeIn" style="color: white; font-size: 20px; font-weight: bolder; margin-top: 3rem">
        粉条留言
      </div>
      <div class="animated fadeIn" style="color: white; font-size: 13px; font-weight: lighter">FANS MESSAGE</div>
      <div @click="visible=true"
           style="margin-top: 1rem; text-align: end; width: 91%; font-size: 12px; font-weight: 500;color: rgba(235,235,235,0.7); cursor: pointer">
        ✏️我要留言
      </div>
      <el-dialog v-model="visible" :show-close="false" width="400"
                 style="background-color: #1e1e1e; border-radius: 1rem">
        <div style="margin-bottom: 1rem; color: white; font-size: 16px; font-weight: 700">留言板</div>
        <el-input
            v-model="inputName"
            style="width: 300px;"
            maxlength="10"
            placeholder="你想留下的 id"
            show-word-limit
            type="text"
        />
        <div style="margin: 20px 20px"/>
        <el-input
            v-model="inputContent"
            maxlength="1000"
            style="width: 300px;"
            placeholder="你想留下的话"
            show-word-limit
            type="textarea"
            rows="15"
        />
        <div style="display: flex; margin-top: 1rem">
          <n-button type="error" ghost style="margin-left: 2.1rem; width: 9rem; height: 2.2rem" @click="close">
            取消
          </n-button>
          <n-button :loading="buttonLoading" type="info" ghost
                    style="margin-left: auto; margin-right: 2.1rem; width: 9rem; height: 2.2rem"
                    @click="send({name: inputName, content: inputContent})">
            确认
          </n-button>
        </div>
        <div style="margin: 10px 0"/>
      </el-dialog>
      <div style="background-color: rgba(235,235,235,0);">
        <div>
          <div v-if="loading" class="loading-spinner">加载中...</div>
          <Waterfall class="animated fadeInUp;" :width="300" :list="state.messageList"
                     style="background-color: rgba(235,235,235,0); border-radius: 10px;">
            <!-- v2.6.0之前版本插槽数据获取 -->
            <!-- <template #item="{ item, url, index }"> -->
            <!-- 新版插槽数据获取 -->
            <template #default="{item}">
              <div class="message-card">
                <div style="display: flex">
                  <div class="message-name">@{{ item.name }}</div>
                  <div class="message-date">{{ transferDate(item.createdAt) }}</div>
                  <div class="message-code">No.{{ item.id }}</div>
                </div>
                <div class="message-content">{{ item.content }}</div>
              </div>
            </template>
          </Waterfall>
        </div>
      </div>
      <n-back-top :right="60" :bottom="60"/>
    </div>
  </div>
</template>

<style scoped>
.whole {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
}

.background-image-container {
  width: 1500px;
  min-height: 120vh;
}

.message-card {
  background-color: rgba(235, 235, 235, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
}

.message-name {
  color: #ebebeb;
  font-size: 16px;
  font-weight: 800;
}

.message-content {
  color: rgb(154, 159, 161);
  text-align: left;
  margin-top: 0.3rem;
}

.message-date {
  color: #ebebeb;
  margin-left: 0.5rem;
  font-size: 12px;
  margin-top: 0.3rem;
}

.message-code {
  color: white;
  font-size: 18px;
  margin-left: auto;
}

.message-11 {
  border-radius: 0.3rem;
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.zcy-words-card {
  color: white;
  font-size: 12px;
  font-weight: 800;
  text-align: left;
  padding: 0.8rem;
  border-radius: 2rem;
}

.loading-spinner {
  color: gray;
  font-size: 16px;
  text-align: center;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-spinner::before {
  content: "";
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color:gray;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>