<script setup>
import {ref} from 'vue';
import {CloseBold} from "@element-plus/icons-vue";

const isMenuVisible = ref(true)
const isSliderOpen = ref(false);

const slide = () => {
  isSliderOpen.value = !isSliderOpen.value;
  isMenuVisible.value = !isMenuVisible.value
};

</script>

<template>
  <div class="whole">
    <div v-if="isMenuVisible" @click="slide" style="position: absolute; left: 6%; margin-top: 0.1rem">
      <div class="menu-line"></div>
      <div class="menu-line"></div>
      <div class="menu-line"></div>
    </div>
    <div v-if="!isMenuVisible" @click="slide" style="position: absolute; left: 5%; margin-top: 0.2rem">
      <el-icon color="#ebebeb" size="28">
        <CloseBold/>
      </el-icon>
    </div>
    <div class="my-title animated">LOVE.ZCY.FOREVER</div>
  </div>
  <div class="my-slider" :class="{ 'hidden':!isSliderOpen , 'open': isSliderOpen, 'animated': true}"
       style="position: fixed; left: -1%; top: 4rem">
    <div style="height: 100vh; width: 70%; background-color: RGB(0,0,0,0.7);">
      <router-link to="/" @click="slide">
        <div class="footer-item" style="padding-top: 3rem">首页</div>
      </router-link>
      <router-link to="/film" @click="slide">
        <div class="footer-item">影视</div>
      </router-link>
      <router-link to="/music" @click="slide">
        <div class="footer-item">音乐</div>
      </router-link>
      <router-link to="/image" @click="slide">
        <div class="footer-item">图集</div>
      </router-link>
      <router-link to="/post" @click="slide">
        <div class="footer-item">更新</div>
      </router-link>
      <router-link to="/message" @click="slide">
        <div class="footer-item">留言</div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

.whole {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  height: 4rem;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}


.my-title {
  color: #ebebeb;
  font-size: 23px;
  font-weight: 800;
  -webkit-text-stroke: 1px #ebebeb; /*文字描边*/
  -webkit-text-fill-color: transparent; /*设置文字的填充颜色*/
  padding: 0.4rem;
  margin-left: auto;
  margin-right: auto;
}

.my-slider.hidden {
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  z-index: 999;
}

.my-slider.open {
  width: 100%;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  z-index: 999;
}

.menu-line {
  height: 3px;
  width: 20px;
  background-color: #ebebeb;
  margin-bottom: 0.3rem;
  border-radius: 1rem;
}

.footer-item {
  text-align: left;
  margin-left: 3.5rem;
  color: #ebebeb;
  margin-bottom: 3rem;
  font-size: 16px;
  font-weight: 500;
}

</style>