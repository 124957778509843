<template>
  <BackgroundVideo v-if="!isMobile">
    <div class="app-container" :style="{ backgroundColor: `rgba(0, 0, 0, ${bgOpacity})` }">
      <MyNavbar class="nav" v-if="!isMobile"></MyNavbar>
      <router-view/>
      <MyFooter class="footer" v-if="!isMobile"></MyFooter>
    </div>
  </BackgroundVideo>
  <div class="background-value" v-if="isMobile">
    <div class="app-container" :style="{ backgroundColor: `rgba(0, 0, 0, ${bgOpacity})` }">
      <MyNavbarH5 class="nav" v-if="isMobile"></MyNavbarH5>
      <router-view/>
      <MyFooterH5 class="footer" v-if="isMobile"></MyFooterH5>
    </div>
  </div>
</template>

<script setup>
import MyNavbar from "@/components/MyNavbar.vue";
import MyFooter from "@/components/MyFooter.vue";
import $ from 'jquery';
import MyNavbarH5 from "@/components/MyNavbarH5.vue";

$(window).on("load", function () {
  $(".loader-wrapper").fadeOut("fast")
});

import {onBeforeMount, ref, onMounted, onUnmounted} from 'vue';
import MyFooterH5 from "@/components/MyFooterH5.vue";
import BackgroundVideo from "@/components/BackgroundVideo.vue";

const isMobile = ref(false);

function checkResolution() {
  const width = window.innerWidth;
  isMobile.value = width < 768;
}

window.addEventListener('load', checkResolution);
window.addEventListener('resize', checkResolution);

onBeforeMount(() => {
  checkResolution()
})

const bgOpacity = ref(0.4);

const handleScroll = () => {
  const scrollPosition = window.scrollY;
  const windowHeight = window.innerHeight;
  
  if (scrollPosition > 0) {
    const newOpacity = Math.min(0.8, 0.5 + (scrollPosition / windowHeight) * 0.3);
    bgOpacity.value = newOpacity;
  } else {
    bgOpacity.value = 0.4;
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: transparent;
}
html {
  background-color: transparent;
}
.app-container {
  min-height: 100vh;
  transition: background-color 0.3s ease;
}
.background-value {
  background-image: url("./assets/image/mybackground.jpg");
  background-attachment: fixed;
}
</style>
