<template>
  <div class="my-navbar animated fadeInDown">
    <div class="my-navbar-box">
      <div class="my-navbar-left">
        <div class="my-title animated bounce">LOVE.ZCY.FOREVER</div>
      </div>
      <div class="my-navbar-center">
        <router-link to="/" class="navbar-font navbar-item">
          <div class="navbar-item" :class="{ 'active-link': $route.path === '/' }">
            首页
          </div>
        </router-link>
        <router-link to="/film" class="navbar-font navbar-item">
          <div class="navbar-item" :class="{ 'active-link': $route.path === '/film' }">
            影视
          </div>
        </router-link>
        <router-link to="/music" class="navbar-font navbar-item">
          <div class="navbar-item" :class="{ 'active-link': $route.path === '/music' }">
            音乐
          </div>
        </router-link>
        <router-link to="/image" class="navbar-font navbar-item">
          <div class="navbar-item" :class="{ 'active-link': $route.path === '/image' }">
            图集
          </div>
        </router-link>
        <router-link to="/message" class="navbar-font navbar-item">
          <div class="navbar-item" :class="{ 'active-link': $route.path === '/message'}">
            留言
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style>

.active-link {
  font-size: 25px;
  color: #f89850 !important;
}

.navbar-item {
  border-radius: 0.25rem;
  width: 4rem;
  height: 2.5rem;
  margin: auto;
  color: rgba(235, 235, 235, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: font-size 0.4s ease, color 0.4s ease, background-color 0.25s ease;
}

.navbar-item:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}

.navbar-font {
  font-size: 14px;
  font-weight: 800;
}

.my-navbar {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  display: flex;
}

.my-navbar-box {
  width: 1500px;
  height: 100px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.my-navbar-left {
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-navbar-center {
  width: 850px;
  display: flex;
  justify-items: center;
  align-items: center;
}

.my-title {
  color: #ebebeb;
  font-size: 18px;
  font-weight: 800;
  -webkit-text-stroke:1px #ebebeb;        /*文字描边*/
  -webkit-text-fill-color:transparent;    /*设置文字的填充颜色*/
}


/* 定义一个名为rotate的动画 */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 应用动画到.rotating-element元素上 */
.rotating-element {
  animation: rotate 6s linear infinite;
}

</style>


