<template>
  <div class="background-video-container">
    <video id="video" autoplay
           muted
           loop
           playsinline
           class="background-video"
    >
      <source src="../assets/WeChat_20241129011353.mp4" type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
  </div>
  <!-- 将内容移到外层 -->
  <div class="content-container">
    <slot></slot>
  </div>
</template>

<script setup>
</script>

<style scoped>
.background-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
</style>
