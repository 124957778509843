<script setup>

// import {ref} from "vue";
import {Waterfall} from 'vue-waterfall-plugin-next'
import 'vue-waterfall-plugin-next/dist/style.css'
import {onMounted, ref} from "vue";
import {imageList} from "@/api/imageService";
import VLazyImage from "v-lazy-image";
const list0 = ref([])

const list = ref([])
const mergedList = ref([]);
const loading = ref(true);

onMounted(() => {
  imageList().then(res => {
    list.value = res.data;
    mergedList.value = [...list.value, ...list0.value]
    loading.value = false;
  })
})

const waterfall = ref(null)

const onLoad = () => {
  if (waterfall.value) {
    waterfall.value.renderer();
  }
}

</script>

<template>
  <div class="whole">
    <div class="background-image-container">
      <audio loop autoplay>
        <source src="../assets/music/可爱女人-周杰伦.mp3">
      </audio>
      <div class="animated fadeIn" style="color: white; font-size: 20px; font-weight: bolder; margin-top: 3rem">
        精选图集
      </div>
      <div class="animated fadeIn" style="color: white; font-size: 13px; font-weight: lighter">FEATURED IMAGES</div>
      
      <div v-if="loading" class="loading-spinner">加载中...</div>
      
      <viewer v-else :images="mergedList">
        <Waterfall ref="waterfall" class="animated fadeInUp" :width="285" :list="mergedList"
                   style="background-color: rgba(235,235,235,0.15); border-radius: 10px; margin-top: 2rem; margin-bottom: 2rem">
          <template #default="{url}">
            <div class="card">
                <transition name="fade">
                    <v-lazy-image :src="url" class="lazy-image" @load="onLoad"/>
                </transition>
            </div>
          </template>
        </Waterfall>
      </viewer>
      <n-back-top :right="40" :bottom="40"/>
    </div>
  </div>
</template>

<style scoped>

.whole {
  min-height: 120vh;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.background-image-container {
  width: 1500px;
  background-size: cover;
  background-position: center;
}

.card {
  background: #fff;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s;
}

.card:hover {
  transform: scale(1.05);
}

.lazy-image {
  width: 100%;
  height: auto;
  display: block;
}

.text {
  color: #333;
  font-size: 14px;
  padding: 10px;
}

.animated.fadeInUp {
  animation-duration: 1.5s; /* 设置动画持续时间为 2 秒 */
}

.animated.fadeIn {
  animation-duration: 1.5s; /* 设置动画持续时间为 2 秒 */
}

.v-lazy-image {
  filter: blur(15px);
  transition: filter 0.8s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.loading-spinner {
  color: gray;
  font-size: 16px;
  text-align: center;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-spinner::before {
  content: "";
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color:gray;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>